import i18next from "i18next";
import { NavLink } from "react-router-dom";
//@ts-ignore
import { OrganizationStore } from "@vegga/front-store";

interface IDropdownItemProps {
  label: string;
  linkTo?: string;
  flag: any;
  isLink?: boolean;
  logOut?: () => void;
  toggleDropdown?: () => void;
  closeSidebarMenu?: () => void;
  features?: string;
}

const DropdownItem = ({
  label,
  linkTo,
  flag,
  logOut,
  isLink,
  toggleDropdown,
  closeSidebarMenu,
  features,
}: IDropdownItemProps) => {
  const organizationStore = OrganizationStore.getInstance();

  const hasFeature = features ? organizationStore.getFeature(features) : true;

  if (!flag || !hasFeature) return null;

  return isLink ? (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? "vegga-navbar__item vegga-navbar__item--active"
          : "vegga-navbar__item"
      }
      to={linkTo}
      onClick={() => {
        toggleDropdown();
        closeSidebarMenu();
      }}
    >
      {i18next.t(label)}
    </NavLink>
  ) : (
    <a
      className="vegga-navbar__item"
      onClick={() => {
        logOut();
        toggleDropdown();
      }}
    >
      {i18next.t("menu.log-out")}
    </a>
  );
};

export default DropdownItem;
