import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // fallbackLng: 'es',
  lng: "es",
  resources: {
    en: {
      translations: require("./locales/en.json"),
    },
    es: {
      translations: require("./locales/es.json"),
    },
    it: {
      translations: require("./locales/it.json"),
    },
    fr: {
      translations: require("./locales/fr.json"),
    },
    pt: {
      translations: require("./locales/pt.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "it", "fr", "pt"];

export default i18n;
