import { OrganizationType } from "../enums/OrganizationType";

export const checkIsOrganizationTypeExcluded = (organizationTypesExcluded: OrganizationType[], organizationType: OrganizationType) => {
    if (!organizationTypesExcluded?.length) return false;

    let isOrganizationExcluded = false;
    organizationTypesExcluded.forEach((type: OrganizationType) => {
      isOrganizationExcluded = organizationType === type;
    });
    return isOrganizationExcluded;
  };