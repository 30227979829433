export let environment = {
  production: true,
  FLAGS: {
    DISPLAY_HOME: "#@#FLAGS_DISPLAY_HOME#@#",
    DISPLAY_MESSAGES: "#@#FLAGS_DISPLAY_MESSAGES#@#",
    DISPLAY_CONFIGURATION: "#@#FLAGS_DISPLAY_CONFIGURATION#@#",
    DISPLAY_NOTIFICATIONS: "#@#FLAGS_DISPLAY_NOTIFICATIONS#@#",
    DISPLAY_PROFILE: "#@#FLAGS_DISPLAY_PROFILE#@#",
    DISPLAY_ABOUT_VEGGA: "#@#FLAGS_DISPLAY_ABOUT_VEGGA#@#",
    DISPLAY_LOG_OUT: "#@#FLAGS_DISPLAY_LOG_OUT#@#",
    DISPLAY_CUSTOMERS: "#@#FLAGS_DISPLAY_CUSTOMERS#@#",
    DISPLAY_HELP: "#@#FLAGS_DISPLAY_HELP#@#",
    DISPLAY_SIDEBAR_FOLD: "#@#FLAGS_DISPLAY_SIDEBAR_FOLD#@#",
  },
};
