import { OrganizationFeaturesEnum } from "@vegga-statics/enums";
import { environment } from "../environments/environment";
import { IDropdownOption, INavbarOption } from "./interfaces/navbarOptions";
import { OrganizationType } from "./enums/OrganizationType";

export const navbarOptions: INavbarOption[] = [
  {
    icon: "home",
    label: "menu.home",
    linkTo: "/home",
    flag: environment.FLAGS.DISPLAY_HOME,
  },
  {
    icon: "customer",
    label: "menu.customers",
    linkTo: "/customers",
    flag: environment.FLAGS.DISPLAY_CUSTOMERS,
    organizationTypesExcluded: [OrganizationType.FARMER]
  },
  {
    icon: "envelope",
    label: "menu.messages",
    linkTo: "/",
    flag: environment.FLAGS.DISPLAY_MESSAGES,
  },
  {
    icon: "settings",
    label: "menu.settings",
    linkTo: "/configuration",
    flag: environment.FLAGS.DISPLAY_CONFIGURATION,
  },
  {
    icon: "bell",
    label: "",
    linkTo: "",
    flag: environment.FLAGS.DISPLAY_NOTIFICATIONS,
  },
];

export const dropdownItems: IDropdownOption[] = [
  {
    label: "menu.profile",
    linkTo: "/profile",
    flag: environment.FLAGS.DISPLAY_PROFILE,
    isLink: true,
    features: OrganizationFeaturesEnum.ORGANIZATION_USER_EDIT_PROFILE_ALLOWED,
  },
  {
    label: "menu.about",
    linkTo: "/about-vegga",
    flag: environment.FLAGS.DISPLAY_ABOUT_VEGGA,
    isLink: true,
  },
  {
    label: "menu.help",
    linkTo: "/help",
    flag: environment.FLAGS.DISPLAY_HELP,
    isLink: true,
  },
  {
    label: "menu.log-out",
    flag: environment.FLAGS.DISPLAY_LOG_OUT,
  },
];
